const Age = [
  {
    id: 1,
    age: 2005,
  },
  {
    id: 2,
    age: 2004,
  },
  {
    id: 3,
    age: 2003,
  },
  {
    id: 4,
    age: 2002,
  },
  {
    id: 5,
    age: 2001,
  },
  {
    id: 6,
    age: 2000,
  },
  {
    id: 7,
    age: 1999,
  },
  {
    id: 8,
    age: 1998,
  },
  {
    id: 9,
    age: 1997,
  },
  {
    id: 10,
    age: 1996,
  },
  {
    id: 11,
    age: 1995,
  },
];

export default Age;
