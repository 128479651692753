import SigninView from '../../components/Auth/SigninView';
import HeaderLayout from '../../layout/HeaderLayout';

export default function AuthSigninPage() {
  return (
    <HeaderLayout>
      <SigninView />
    </HeaderLayout>
  );
}
