import { createSlice } from '@reduxjs/toolkit';

export const APPLY_STORAGE_KEY = 'apply-data';

const initialState = {
  finishedStep: 0,
  memberCount: 3, // 2: 2대2, 3: 3대3
  teamAvailableDate: [],
  areas: [],
  city: 0,
  members: [],
  teamName: '',
  intro: '',
  drink: 3,
  memberCounts: [],
  prefAge: [20, 29],
  prefVibes: [],
  kakaoId: '',
};

const applyData = JSON.parse(localStorage.getItem(APPLY_STORAGE_KEY));
const isPreviousVersion = applyData
  ? Object.hasOwn(applyData, 'gender') ||
    Object.hasOwn(applyData, 'universities') ||
    Object.hasOwn(applyData, 'availableDates') ||
    Object.hasOwn(applyData, 'prefSameUniversity')
  : false;

const initialApplyData = isPreviousVersion ? {} : { ...applyData };

const applySlice = createSlice({
  name: 'apply',
  initialState: {
    ...initialState,
    ...initialApplyData,
  },
  reducers: {
    submitStep1: (state, action) => {
      const { memberCount } = action.payload;

      state.finishedStep = 1;
      state.memberCount = memberCount;

      const stored = localStorage.getItem('apply-data');
      localStorage.setItem(
        APPLY_STORAGE_KEY,
        JSON.stringify({
          ...JSON.parse(stored),
          ...action.payload,
          finishedStep: state.finishedStep,
        }),
      );
    },
    submitStep2: (state, action) => {
      const { teamAvailableDate } = action.payload;

      state.finishedStep = 2;
      state.teamAvailableDate = teamAvailableDate;

      const stored = localStorage.getItem('apply-data');
      localStorage.setItem(
        APPLY_STORAGE_KEY,
        JSON.stringify({
          ...JSON.parse(stored),
          ...action.payload,
          finishedStep: state.finishedStep,
        }),
      );
    },
    submitStep3: (state, action) => {
      const { areas, city } = action.payload;

      state.finishedStep = 3;
      state.areas = areas;
      state.city = city;

      const stored = localStorage.getItem('apply-data');
      localStorage.setItem(
        APPLY_STORAGE_KEY,
        JSON.stringify({
          ...JSON.parse(stored),
          ...action.payload,
          finishedStep: state.finishedStep,
        }),
      );
    },
    submitStep4: (state, action) => {
      const { members } = action.payload;

      state.finishedStep = 4;
      state.members = members;

      const stored = localStorage.getItem('apply-data');
      localStorage.setItem(
        APPLY_STORAGE_KEY,
        JSON.stringify({
          ...JSON.parse(stored),
          ...action.payload,
          finishedStep: state.finishedStep,
        }),
      );
    },
    submitStep5: (state, action) => {
      const { intro, teamName } = action.payload;

      state.finishedStep = 5;
      state.intro = intro;
      state.teamName = teamName;

      const stored = localStorage.getItem('apply-data');
      localStorage.setItem(
        APPLY_STORAGE_KEY,
        JSON.stringify({
          ...JSON.parse(stored),
          ...action.payload,
          finishedStep: state.finishedStep,
        }),
      );
    },
    submitStep6: (state, action) => {
      const { prefAge, prefVibes } = action.payload;

      state.finishedStep = 6;
      state.prefAge = prefAge;
      state.prefVibes = prefVibes;

      const stored = localStorage.getItem('apply-data');
      localStorage.setItem(
        APPLY_STORAGE_KEY,
        JSON.stringify({
          ...JSON.parse(stored),
          ...action.payload,
          finishedStep: state.finishedStep,
        }),
      );
    },
    submitStep7: (state, action) => {
      const { drink, memberCounts } = action.payload;

      state.finishedStep = 7;
      state.drink = drink;
      state.memberCounts = memberCounts;

      const stored = localStorage.getItem('apply-data');
      localStorage.setItem(
        APPLY_STORAGE_KEY,
        JSON.stringify({
          ...JSON.parse(stored),
          ...action.payload,
          finishedStep: state.finishedStep,
        }),
      );
    },
    submitStep8: (state, action) => {
      const { kakaoId } = action.payload;

      state.finishedStep = 8;
      state.kakaoId = kakaoId;

      const stored = localStorage.getItem('apply-data');
      localStorage.setItem(
        APPLY_STORAGE_KEY,
        JSON.stringify({
          ...JSON.parse(stored),
          ...action.payload,
          finishedStep: state.finishedStep,
        }),
      );
    },
    submitDate: (state, action) => {
      const { availableDates } = action.payload;

      state.finishedStep = 5; // 날짜만 재선택하는 경우이므로
      state.availableDates = availableDates;

      const stored = localStorage.getItem('apply-data');
      localStorage.setItem(
        APPLY_STORAGE_KEY,
        JSON.stringify({
          ...JSON.parse(stored),
          ...action.payload,
          finishedStep: state.finishedStep,
        }),
      );
    },
  },
});

export const {
  submitStep1,
  submitStep2,
  submitStep3,
  submitStep4,
  submitStep5,
  submitStep6,
  submitStep7,
  submitStep8,
  submitDate,
} = applySlice.actions;

export default applySlice.reducer;
