import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { ReactComponent as CheckValid } from '../../asset/svg/CheckValid.svg';
import { ReactComponent as CheckInvalid } from '../../asset/svg/CheckInvalid.svg';
import ChannelTalk from '../../asset/ChannelTalk';
import {
  useGetHashQuery,
  usePostAgreementsMutation,
} from '../../features/api/userApi';
import backend from '../../util/backend';
import { logout } from '../../features/user/asyncActions';
import HeaderLayout from '../../layout/HeaderLayout';
import { API_URL } from '../../config/constants';

function AgreePage() {
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [agree3, setAgree3] = useState(false);
  const [agree4, setAgree4] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [postAgreements] = usePostAgreementsMutation();
  const { data: hash } = useGetHashQuery();

  const handleAgree = useCallback(() => {
    setAgree1(true);
    setAgree2(true);
    setAgree3(true);
    setAgree4(true);
  }, [agree1, agree2, agree3, agree4]);

  const NextPage = useCallback(async () => {
    try {
      await postAgreements({
        service: agree1,
        privacy: agree2,
        age: agree3,
        marketing: agree4,
      }).unwrap();
      // pass 연동
      const dataToSend = {
        ...hash,
        Ret_URL: `${API_URL}/auth/hash`,
      };

      const queryString = Object.keys(dataToSend)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(dataToSend[key])}`,
        )
        .join('&');

      window.open(
        `https://cert.kcp.co.kr/kcp_cert/cert_view.jsp?${queryString}`,
        '_parent',
      );
    } catch (err) {
      window.alert('처음부터 다시 시도해주세요');
      await backend.delete('/auth/account', {
        withCredentials: true,
      });
      dispatch(logout());
      localStorage.clear();
      navigate('/');
    }
  });

  return (
    <HeaderLayout>
      <Title>
        <Maintitle>미팅학개론 이용을 위해</Maintitle>
        <Maintitle>약관에 동의해 주세요</Maintitle>
      </Title>
      <Container>
        <AllAgreeBtn
          onClick={handleAgree}
          selected={agree1 && agree2 && agree3 && agree4}
        >
          네, 모두 동의합니다
        </AllAgreeBtn>
        <CheckBox>
          <CheckingContent
            onClick={() => {
              setAgree1((prev) => !prev);
            }}
          >
            {agree1 ? <CheckValid /> : <CheckInvalid />}
            {agree1 ? (
              <SA
                href="https://www.notion.so/moingclub/4e3c4d1f8306494b9a54fc2226e9a3b7?pvs=4"
                target="_blank"
                rel="noreferrer"
              >
                서비스 이용약관 동의
              </SA>
            ) : (
              '서비스 이용약관 동의'
            )}
            <Pink>&nbsp;&nbsp;(필수)</Pink>
          </CheckingContent>
          <CheckingContent
            onClick={() => {
              setAgree2((prev) => !prev);
            }}
          >
            {agree2 ? <CheckValid /> : <CheckInvalid />}
            {agree2 ? (
              <SA
                href="https://www.notion.so/moingclub/c83f4127e3c54b7080c333aa31a4cc03?pvs=4"
                target="_blank"
                rel="noreferrer"
              >
                개인정보 수집 및 이용 동의
              </SA>
            ) : (
              '개인정보 수집 및 이용 동의'
            )}
            <Pink>&nbsp;&nbsp;(필수)</Pink>
          </CheckingContent>
          <CheckingContent
            onClick={() => {
              setAgree3((prev) => !prev);
            }}
          >
            {agree3 ? <CheckValid /> : <CheckInvalid />}만 19세 이상 입니다.
            <Pink>&nbsp;&nbsp;(필수)</Pink>
          </CheckingContent>
          <CheckingContent
            onClick={() => {
              setAgree4((prev) => !prev);
            }}
          >
            {agree4 ? <CheckValid /> : <CheckInvalid />}이벤트/혜택 정보 수신
            동의<Pink>&nbsp;&nbsp;(선택)</Pink>
          </CheckingContent>
        </CheckBox>
        <form name="form_auth" method="post" acceptCharset="EUC-KR">
          <div id="sbParam" style={{ display: 'none' }} />
        </form>
      </Container>
      <Footer>
        <SubmitButton
          onClick={NextPage}
          disabled={!agree1 || !agree2 || !agree3}
        >
          다음
        </SubmitButton>
        <div>{ChannelTalk.hideChannelButton()}</div>
      </Footer>
    </HeaderLayout>
  );
}

export default AgreePage;

const SA = styled.a`
  text-decoration: none;
  color: black;
`;

const Title = styled.div`
  width: 90%;
  margin: 10% 0;
`;

const Maintitle = styled.div`
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
`;

const Pink = styled.span`
  color: ${(props) => props.theme.pink};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

const CheckBox = styled.div`
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 80%;
`;

const CheckingContent = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  width: 90%;
  &:hover {
    cursor: pointer;
  }
`;

const SubmitButton = styled(Button)`
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  border: none;
  width: 100%;
  height: 50px;
  background: #eb8888;
  border-radius: 10px;
`;

const AllAgreeBtn = styled(SubmitButton)`
  color: ${({ selected }) => (selected ? '#ffffff' : '#bbbbbb')};
  background: ${({ selected }) => (selected ? '#eb8888' : '#ededed')};
`;

const Footer = styled.div`
  width: 90%;
  margin: auto 0 10%;
`;
