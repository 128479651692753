/** @type {import('antd/es/config-provider/context').ThemeConfig} */
const antdTheme = {
  token: {
    fontFamily: 'Pretendard',
    colorPrimary: '#eb8888',
  },
  components: {
    Carousel: {
      dotActiveWidth: 'none',
    },
  },
};

export default antdTheme;
