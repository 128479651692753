const Mbti = [
  {
    name: 'ENFJ',
    id: 1,
  },
  {
    name: 'ENFP',
    id: 2,
  },
  {
    name: 'ENTJ',
    id: 3,
  },
  {
    name: 'ENTP',
    id: 4,
  },
  {
    name: 'ESFJ',
    id: 5,
  },
  {
    name: 'ESFP',
    id: 6,
  },
  {
    name: 'ESTJ',
    id: 7,
  },
  {
    name: 'ESTP',
    id: 8,
  },
  {
    name: 'INFJ',
    id: 9,
  },
  {
    name: 'INFP',
    id: 10,
  },
  {
    name: 'INTJ',
    id: 11,
  },
  {
    name: 'INTP',
    id: 12,
  },
  {
    name: 'ISFJ',
    id: 13,
  },
  {
    name: 'ISFP',
    id: 14,
  },
  {
    name: 'ISTJ',
    id: 15,
  },
  {
    name: 'ISTP',
    id: 16,
  },
  {
    name: '잘 모름',
    id: 17,
  },
];

export default Mbti;
