const Universities = [
  {
    id: 1,
    name: '동양미래대학교',
    grade: 1,
  },
  {
    id: 2,
    name: '명지전문대학',
    grade: 1,
  },
  {
    id: 3,
    name: '배화여자대학교',
    grade: 1,
  },
  {
    id: 4,
    name: '삼육보건대학교',
    grade: 1,
  },
  {
    id: 5,
    name: '서울여자간호대학교',
    grade: 1,
  },
  {
    id: 6,
    name: '적십자간호대학',
    grade: 1,
  },
  {
    id: 7,
    name: '경남정보대학교',
    grade: 1,
  },
  {
    id: 8,
    name: '동부산대학교',
    grade: 1,
  },
  {
    id: 9,
    name: '동주대학교',
    grade: 1,
  },
  {
    id: 10,
    name: '계명문화대학교',
    grade: 1,
  },
  {
    id: 11,
    name: '대구보건대학교',
    grade: 1,
  },
  {
    id: 12,
    name: '수성대학교',
    grade: 1,
  },
  {
    id: 13,
    name: '경인여자대학교',
    grade: 1,
  },
  {
    id: 14,
    name: '인하공업전문대학',
    grade: 1,
  },
  {
    id: 15,
    name: '광주보건대학교',
    grade: 1,
  },
  {
    id: 16,
    name: '기독간호대학교',
    grade: 1,
  },
  {
    id: 17,
    name: '서영대학교',
    grade: 1,
  },
  {
    id: 18,
    name: '송원대학',
    grade: 1,
  },
  {
    id: 19,
    name: '조선간호대학교',
    grade: 1,
  },
  {
    id: 20,
    name: '우송정보대학',
    grade: 1,
  },
  {
    id: 21,
    name: '울산과학대학교',
    grade: 1,
  },
  {
    id: 22,
    name: '춘해보건대학교',
    grade: 1,
  },
  {
    id: 23,
    name: '한국영상대학교',
    grade: 1,
  },
  {
    id: 24,
    name: '경민대학교',
    grade: 1,
  },
  {
    id: 25,
    name: '경복대학교',
    grade: 1,
  },
  {
    id: 26,
    name: '김포대학교',
    grade: 1,
  },
  {
    id: 27,
    name: '농협대학교',
    grade: 4,
  },
  {
    id: 28,
    name: '대림대학교',
    grade: 1,
  },
  {
    id: 29,
    name: '동남보건대학교',
    grade: 1,
  },
  {
    id: 30,
    name: '동아방송예술대학교',
    grade: 3,
  },
  {
    id: 31,
    name: '동원대학교',
    grade: 1,
  },
  {
    id: 32,
    name: '서울예술대학교',
    grade: 4,
  },
  {
    id: 33,
    name: '서정대학교',
    grade: 1,
  },
  {
    id: 34,
    name: '수원과학대학교',
    grade: 1,
  },
  {
    id: 35,
    name: '신구대학교',
    grade: 1,
  },
  {
    id: 36,
    name: '안산대학교',
    grade: 1,
  },
  {
    id: 37,
    name: '연성대학교',
    grade: 1,
  },
  {
    id: 38,
    name: '오산대학교',
    grade: 1,
  },
  {
    id: 39,
    name: '용인송담대학교',
    grade: 1,
  },
  {
    id: 40,
    name: '웅지세무대학교',
    grade: 1,
  },
  {
    id: 41,
    name: '청강문화산업대학교',
    grade: 1,
  },
  {
    id: 42,
    name: '한국복지대학교',
    grade: 1,
  },
  {
    id: 43,
    name: '강릉영동대학교',
    grade: 1,
  },
  {
    id: 44,
    name: '한국골프대학',
    grade: 1,
  },
  {
    id: 45,
    name: '한림성심대학교',
    grade: 1,
  },
  {
    id: 46,
    name: '백석문화대학교',
    grade: 1,
  },
  {
    id: 47,
    name: '신성대학교',
    grade: 1,
  },
  {
    id: 48,
    name: '충남도립대학교',
    grade: 1,
  },
  {
    id: 49,
    name: '혜전대학교',
    grade: 1,
  },
  {
    id: 50,
    name: '군산간호대학교',
    grade: 1,
  },
  {
    id: 51,
    name: '군장대학교',
    grade: 1,
  },
  {
    id: 52,
    name: '서해대학',
    grade: 1,
  },
  {
    id: 53,
    name: '원광보건대학교',
    grade: 1,
  },
  {
    id: 54,
    name: '전주기전대학',
    grade: 1,
  },
  {
    id: 55,
    name: '한국농수산대학',
    grade: 1,
  },
  {
    id: 56,
    name: '고구려대학교',
    grade: 1,
  },
  {
    id: 57,
    name: '광양보건대학교',
    grade: 1,
  },
  {
    id: 58,
    name: '동아인재대학교',
    grade: 1,
  },
  {
    id: 59,
    name: '목포과학대학교',
    grade: 1,
  },
  {
    id: 60,
    name: '청암대학교',
    grade: 1,
  },
  {
    id: 61,
    name: '가톨릭상지대학교',
    grade: 1,
  },
  {
    id: 62,
    name: '경북전문대학교',
    grade: 1,
  },
  {
    id: 63,
    name: '대구미래대학교',
    grade: 1,
  },
  {
    id: 64,
    name: '서라벌대학교',
    grade: 1,
  },
  {
    id: 65,
    name: '선린대학교',
    grade: 1,
  },
  {
    id: 66,
    name: '성덕대학교',
    grade: 1,
  },
  {
    id: 67,
    name: '안동과학대학교',
    grade: 1,
  },
  {
    id: 68,
    name: '포항대학교',
    grade: 1,
  },
  {
    id: 69,
    name: '거제대학교',
    grade: 1,
  },
  {
    id: 70,
    name: '김해대학교',
    grade: 1,
  },
  {
    id: 71,
    name: '마산대학교',
    grade: 1,
  },
  {
    id: 72,
    name: '연암공업대학',
    grade: 1,
  },
  {
    id: 73,
    name: '진주보건대학교',
    grade: 1,
  },
  {
    id: 74,
    name: '창원문성대학',
    grade: 1,
  },
  {
    id: 75,
    name: '제주산업정보대학',
    grade: 1,
  },
  {
    id: 76,
    name: '제주한라대학교',
    grade: 1,
  },
  {
    id: 77,
    name: '서일대학교',
    grade: 1,
  },
  {
    id: 78,
    name: '숭의여자대학교',
    grade: 1,
  },
  {
    id: 79,
    name: '인덕대학교',
    grade: 1,
  },
  {
    id: 80,
    name: '한양여자대학교',
    grade: 1,
  },
  {
    id: 81,
    name: '대동대학교',
    grade: 1,
  },
  {
    id: 82,
    name: '동의과학대학교',
    grade: 1,
  },
  {
    id: 83,
    name: '부산경상대학교',
    grade: 1,
  },
  {
    id: 84,
    name: '부산과학기술대학교',
    grade: 1,
  },
  {
    id: 85,
    name: '부산여자대학교',
    grade: 1,
  },
  {
    id: 86,
    name: '부산예술대학교',
    grade: 1,
  },
  {
    id: 87,
    name: '성심외국어대학',
    grade: 1,
  },
  {
    id: 88,
    name: '대구공업대학교',
    grade: 1,
  },
  {
    id: 89,
    name: '대구과학대학교',
    grade: 1,
  },
  {
    id: 90,
    name: '영남이공대학교',
    grade: 1,
  },
  {
    id: 91,
    name: '영진전문대학',
    grade: 1,
  },
  {
    id: 92,
    name: '가천길대학',
    grade: 1,
  },
  {
    id: 93,
    name: '인천재능대학교',
    grade: 1,
  },
  {
    id: 94,
    name: '인천전문대학',
    grade: 1,
  },
  {
    id: 95,
    name: '동강대학교',
    grade: 1,
  },
  {
    id: 96,
    name: '조선이공대학교',
    grade: 1,
  },
  {
    id: 97,
    name: '대덕대학교',
    grade: 1,
  },
  {
    id: 98,
    name: '대전과학기술대학교',
    grade: 1,
  },
  {
    id: 99,
    name: '대전보건대학교',
    grade: 1,
  },
  {
    id: 100,
    name: '우송공업대학',
    grade: 1,
  },
  {
    id: 101,
    name: '경기과학기술대학교',
    grade: 1,
  },
  {
    id: 102,
    name: '경원전문대학',
    grade: 1,
  },
  {
    id: 103,
    name: '계원예술대학교',
    grade: 3,
  },
  {
    id: 104,
    name: '국제대학교',
    grade: 1,
  },
  {
    id: 105,
    name: '동서울대학교',
    grade: 1,
  },
  {
    id: 106,
    name: '두원공과대학교',
    grade: 1,
  },
  {
    id: 107,
    name: '부천대학교',
    grade: 1,
  },
  {
    id: 108,
    name: '서울보건대학',
    grade: 1,
  },
  {
    id: 109,
    name: '수원여자대학교',
    grade: 1,
  },
  {
    id: 110,
    name: '신안산대학교',
    grade: 1,
  },
  {
    id: 111,
    name: '신흥대학교',
    grade: 1,
  },
  {
    id: 112,
    name: '여주대학교',
    grade: 1,
  },
  {
    id: 113,
    name: '유한대학교',
    grade: 1,
  },
  {
    id: 114,
    name: '장안대학교',
    grade: 1,
  },
  {
    id: 115,
    name: '한국관광대학교',
    grade: 1,
  },
  {
    id: 116,
    name: '한국철도대학',
    grade: 1,
  },
  {
    id: 117,
    name: '강원관광대학교',
    grade: 1,
  },
  {
    id: 118,
    name: '강원도립대학',
    grade: 1,
  },
  {
    id: 119,
    name: '동우대학',
    grade: 1,
  },
  {
    id: 120,
    name: '상지영서대학교',
    grade: 1,
  },
  {
    id: 121,
    name: '세경대학교',
    grade: 1,
  },
  {
    id: 122,
    name: '송곡대학교',
    grade: 1,
  },
  {
    id: 123,
    name: '송호대학교',
    grade: 1,
  },
  {
    id: 124,
    name: '강동대학교',
    grade: 1,
  },
  {
    id: 125,
    name: '대원대학교',
    grade: 1,
  },
  {
    id: 126,
    name: '충북도립대학',
    grade: 1,
  },
  {
    id: 127,
    name: '충북보건과학대학교',
    grade: 1,
  },
  {
    id: 128,
    name: '충청대학교',
    grade: 1,
  },
  {
    id: 129,
    name: '아주자동차대학',
    grade: 1,
  },
  {
    id: 130,
    name: '천안연암대학',
    grade: 1,
  },
  {
    id: 131,
    name: '백제예술대학교',
    grade: 1,
  },
  {
    id: 132,
    name: '전북과학대학교',
    grade: 1,
  },
  {
    id: 133,
    name: '전주비전대학교',
    grade: 1,
  },
  {
    id: 134,
    name: '순천제일대학교',
    grade: 1,
  },
  {
    id: 135,
    name: '전남과학대학교',
    grade: 1,
  },
  {
    id: 136,
    name: '전남도립대학교',
    grade: 1,
  },
  {
    id: 137,
    name: '한영대학',
    grade: 1,
  },
  {
    id: 138,
    name: '경북과학대학교',
    grade: 1,
  },
  {
    id: 139,
    name: '경북도립대학교',
    grade: 1,
  },
  {
    id: 140,
    name: '구미대학교',
    grade: 1,
  },
  {
    id: 141,
    name: '김천과학대학교',
    grade: 1,
  },
  {
    id: 142,
    name: '김천대학',
    grade: 1,
  },
  {
    id: 143,
    name: '대경대학교',
    grade: 1,
  },
  {
    id: 144,
    name: '문경대학교',
    grade: 1,
  },
  {
    id: 145,
    name: '영남외국어대학',
    grade: 1,
  },
  {
    id: 146,
    name: '호산대학교',
    grade: 1,
  },
  {
    id: 147,
    name: '경남도립거창대학',
    grade: 1,
  },
  {
    id: 148,
    name: '경남도립남해대학',
    grade: 1,
  },
  {
    id: 149,
    name: '동원과학기술대학교',
    grade: 1,
  },
  {
    id: 150,
    name: '창신대학',
    grade: 1,
  },
  {
    id: 151,
    name: '한국승강기대학교',
    grade: 1,
  },
  {
    id: 152,
    name: '제주관광대학교',
    grade: 1,
  },
  {
    id: 153,
    name: '서울교육대학교',
    grade: 6,
  },
  {
    id: 154,
    name: '부산교육대학교',
    grade: 4,
  },
  {
    id: 155,
    name: '대구교육대학교',
    grade: 4,
  },
  {
    id: 156,
    name: '경인교육대학교',
    grade: 5,
  },
  {
    id: 157,
    name: '광주교육대학교',
    grade: 4,
  },
  {
    id: 158,
    name: '춘천교육대학교',
    grade: 4,
  },
  {
    id: 159,
    name: '청주교육대학교',
    grade: 4,
  },
  {
    id: 160,
    name: '공주교육대학교',
    grade: 4,
  },
  {
    id: 161,
    name: '전주교육대학교',
    grade: 4,
  },
  {
    id: 162,
    name: '진주교육대학교',
    grade: 4,
  },
  {
    id: 163,
    name: '가톨릭대학교',
    grade: 4,
  },
  {
    id: 164,
    name: '감리교신학대학교',
    grade: 1,
  },
  {
    id: 165,
    name: '건국대학교',
    grade: 5,
  },
  {
    id: 166,
    name: '경기대학교',
    grade: 4,
  },
  {
    id: 167,
    name: '경희대학교',
    grade: 6,
  },
  {
    id: 168,
    name: '고려대학교',
    grade: 7,
  },
  {
    id: 169,
    name: '광운대학교',
    grade: 4,
  },
  {
    id: 170,
    name: '국민대학교',
    grade: 5,
  },
  {
    id: 171,
    name: '그리스도대학교',
    grade: 1,
  },
  {
    id: 172,
    name: '덕성여자대학교',
    grade: 5,
  },
  {
    id: 173,
    name: '동국대학교',
    grade: 5,
  },
  {
    id: 174,
    name: '동덕여자대학교',
    grade: 4,
  },
  {
    id: 175,
    name: '명지대학교 인문캠퍼스',
    grade: 4,
  },
  {
    id: 176,
    name: '삼육대학교',
    grade: 4,
  },
  {
    id: 177,
    name: '상명대학교',
    grade: 4,
  },
  {
    id: 178,
    name: '서강대학교',
    grade: 7,
  },
  {
    id: 179,
    name: '서경대학교',
    grade: 2,
  },
  {
    id: 180,
    name: '서울과학기술대학교',
    grade: 4,
  },
  {
    id: 181,
    name: '서울기독대학교',
    grade: 1,
  },
  {
    id: 182,
    name: '서울대학교',
    grade: 7,
  },
  {
    id: 183,
    name: '서울시립대학교',
    grade: 6,
  },
  {
    id: 184,
    name: '서울여자대학교',
    grade: 3,
  },
  {
    id: 185,
    name: '성공회대학교',
    grade: 3,
  },
  {
    id: 186,
    name: '성균관대학교',
    grade: 7,
  },
  {
    id: 187,
    name: '성신여자대학교',
    grade: 5,
  },
  {
    id: 188,
    name: '세종대학교',
    grade: 5,
  },
  {
    id: 189,
    name: '숙명여자대학교',
    grade: 5,
  },
  {
    id: 190,
    name: '숭실대학교',
    grade: 5,
  },
  {
    id: 191,
    name: '연세대학교',
    grade: 7,
  },
  {
    id: 192,
    name: '이화여자대학교',
    grade: 6,
  },
  {
    id: 193,
    name: '장로회신학대학교',
    grade: 1,
  },
  {
    id: 194,
    name: '중앙대학교 서울캠퍼스',
    grade: 6,
  },
  {
    id: 195,
    name: '총신대학교',
    grade: 1,
  },
  {
    id: 196,
    name: '추계예술대학교',
    grade: 3,
  },
  {
    id: 197,
    name: '한국성서대학교',
    grade: 1,
  },
  {
    id: 198,
    name: '한국외국어대학교',
    grade: 6,
  },
  {
    id: 199,
    name: '한국체육대학교',
    grade: 5,
  },
  {
    id: 200,
    name: '한성대학교',
    grade: 4,
  },
  {
    id: 201,
    name: '한양대학교',
    grade: 7,
  },
  {
    id: 202,
    name: '한영신학대학교',
    grade: 1,
  },
  {
    id: 203,
    name: '홍익대학교',
    grade: 5,
  },
  {
    id: 204,
    name: '경성대학교',
    grade: 3,
  },
  {
    id: 205,
    name: '고신대학교',
    grade: 3,
  },
  {
    id: 206,
    name: '동명대학교',
    grade: 2,
  },
  {
    id: 207,
    name: '동서대학교',
    grade: 2,
  },
  {
    id: 208,
    name: '동아대학교',
    grade: 3,
  },
  {
    id: 209,
    name: '동의대학교',
    grade: 3,
  },
  {
    id: 210,
    name: '부경대학교',
    grade: 4,
  },
  {
    id: 211,
    name: '부산가톨릭대학교',
    grade: 2,
  },
  {
    id: 212,
    name: '부산대학교',
    grade: 5,
  },
  {
    id: 213,
    name: '부산외국어대학교',
    grade: 3,
  },
  {
    id: 214,
    name: '신라대학교',
    grade: 2,
  },
  {
    id: 215,
    name: '영산대학교',
    grade: 3,
  },
  {
    id: 216,
    name: '인제대학교 ',
    grade: 3,
  },
  {
    id: 217,
    name: '한국해양대학교',
    grade: 1,
  },
  {
    id: 218,
    name: '경북대학교',
    grade: 5,
  },
  {
    id: 219,
    name: '계명대학교',
    grade: 3,
  },
  {
    id: 220,
    name: '대구경북과학기술원',
    grade: 6,
  },
  {
    id: 221,
    name: '안양대학교',
    grade: 1,
  },
  {
    id: 222,
    name: '인천가톨릭대학교',
    grade: 1,
  },
  {
    id: 223,
    name: '인천대학교',
    grade: 4,
  },
  {
    id: 224,
    name: '인하대학교',
    grade: 4,
  },
  {
    id: 225,
    name: '광신대학교',
    grade: 1,
  },
  {
    id: 226,
    name: '광주과학기술원',
    grade: 6,
  },
  {
    id: 227,
    name: '광주대학교',
    grade: 1,
  },
  {
    id: 228,
    name: '광주여자대학교',
    grade: 1,
  },
  {
    id: 229,
    name: '남부대학교',
    grade: 1,
  },
  {
    id: 230,
    name: '송원대학교',
    grade: 1,
  },
  {
    id: 231,
    name: '전남대학교',
    grade: 5,
  },
  {
    id: 232,
    name: '조선대학교',
    grade: 3,
  },
  {
    id: 233,
    name: '호남대학교',
    grade: 1,
  },
  {
    id: 234,
    name: '호남신학대학교',
    grade: 1,
  },
  {
    id: 235,
    name: '건양대학교',
    grade: 2,
  },
  {
    id: 236,
    name: '대전대학교',
    grade: 2,
  },
  {
    id: 237,
    name: '대전신학대학교',
    grade: 1,
  },
  {
    id: 238,
    name: '목원대학교',
    grade: 1,
  },
  {
    id: 239,
    name: '배재대학교',
    grade: 1,
  },
  {
    id: 240,
    name: '우송대학교',
    grade: 1,
  },
  {
    id: 241,
    name: '을지대학교',
    grade: 1,
  },
  {
    id: 242,
    name: '충남대학교',
    grade: 5,
  },
  {
    id: 243,
    name: '침례신학대학교',
    grade: 1,
  },
  {
    id: 244,
    name: '한국과학기술원',
    grade: 7,
  },
  {
    id: 245,
    name: '한남대학교',
    grade: 3,
  },
  {
    id: 246,
    name: '한밭대학교',
    grade: 3,
  },
  {
    id: 247,
    name: '울산과학기술대학교',
    grade: 6,
  },
  {
    id: 248,
    name: '울산대학교',
    grade: 4,
  },
  {
    id: 249,
    name: '고려대학교 세종캠퍼스',
    grade: 4,
  },
  {
    id: 250,
    name: '대전가톨릭대학교',
    grade: 1,
  },
  {
    id: 251,
    name: '홍익대학교 세종캠퍼스',
    grade: 1,
  },
  {
    id: 252,
    name: '가천대학교',
    grade: 4,
  },
  {
    id: 253,
    name: '강남대학교',
    grade: 3,
  },
  {
    id: 254,
    name: '경동대학교',
    grade: 2,
  },
  {
    id: 255,
    name: '단국대학교',
    grade: 5,
  },
  {
    id: 256,
    name: '대진대학교',
    grade: 1,
  },
  {
    id: 257,
    name: '루터대학교',
    grade: 1,
  },
  {
    id: 258,
    name: '명지대학교 자연캠퍼스',
    grade: 4,
  },
  {
    id: 259,
    name: '서울신학대학교',
    grade: 2,
  },
  {
    id: 260,
    name: '서울장신대학교',
    grade: 1,
  },
  {
    id: 261,
    name: '성결대학교',
    grade: 2,
  },
  {
    id: 262,
    name: '수원가톨릭대학교',
    grade: 1,
  },
  {
    id: 263,
    name: '수원대학교',
    grade: 3,
  },
  {
    id: 264,
    name: '신경대학교',
    grade: 1,
  },
  {
    id: 265,
    name: '신한대학교 ',
    grade: 1,
  },
  {
    id: 266,
    name: '아세아연합신학대학교',
    grade: 1,
  },
  {
    id: 267,
    name: '아주대학교',
    grade: 5,
  },
  {
    id: 268,
    name: '예원예술대학교 양주캠퍼스',
    grade: 1,
  },
  {
    id: 269,
    name: '용인대학교',
    grade: 3,
  },
  {
    id: 270,
    name: '중부대학교',
    grade: 1,
  },
  {
    id: 271,
    name: '중앙대학교 안성캠퍼스',
    grade: 4,
  },
  {
    id: 272,
    name: '중앙승가대학교',
    grade: 1,
  },
  {
    id: 273,
    name: '차의과학대학교',
    grade: 1,
  },
  {
    id: 274,
    name: '칼빈대학교',
    grade: 1,
  },
  {
    id: 275,
    name: '평택대학교',
    grade: 1,
  },
  {
    id: 276,
    name: '한경대학교',
    grade: 1,
  },
  {
    id: 277,
    name: '한국산업기술대학교',
    grade: 3,
  },
  {
    id: 278,
    name: '한국항공대학교',
    grade: 5,
  },
  {
    id: 279,
    name: '한북대학교',
    grade: 1,
  },
  {
    id: 280,
    name: '한세대학교',
    grade: 1,
  },
  {
    id: 281,
    name: '한신대학교',
    grade: 1,
  },
  {
    id: 282,
    name: '협성대학교',
    grade: 2,
  },
  {
    id: 283,
    name: '가톨릭관동대학교',
    grade: 1,
  },
  {
    id: 284,
    name: '강릉원주대학교',
    grade: 3,
  },
  {
    id: 285,
    name: '강원대학교',
    grade: 4,
  },
  {
    id: 286,
    name: '상지대학교',
    grade: 1,
  },
  {
    id: 287,
    name: '연세대학교 원주캠퍼스',
    grade: 4,
  },
  {
    id: 288,
    name: '한라대학교',
    grade: 1,
  },
  {
    id: 289,
    name: '한림대학교',
    grade: 1,
  },
  {
    id: 290,
    name: '한중대학교',
    grade: 1,
  },
  {
    id: 291,
    name: '건국대학교 글로컬캠퍼스',
    grade: 1,
  },
  {
    id: 292,
    name: '극동대학교',
    grade: 1,
  },
  {
    id: 293,
    name: '꽃동네대학교',
    grade: 1,
  },
  {
    id: 294,
    name: '서원대학교',
    grade: 1,
  },
  {
    id: 295,
    name: '세명대학교',
    grade: 1,
  },
  {
    id: 296,
    name: '영동대학교',
    grade: 1,
  },
  {
    id: 297,
    name: '중원대학교',
    grade: 1,
  },
  {
    id: 298,
    name: '청주대학교',
    grade: 1,
  },
  {
    id: 299,
    name: '충북대학교',
    grade: 4,
  },
  {
    id: 300,
    name: '한국교원대학교',
    grade: 4,
  },
  {
    id: 301,
    name: '한국교통대학교',
    grade: 1,
  },
  {
    id: 302,
    name: '공주대학교',
    grade: 1,
  },
  {
    id: 303,
    name: '금강대학교',
    grade: 1,
  },
  {
    id: 304,
    name: '나사렛대학교',
    grade: 1,
  },
  {
    id: 305,
    name: '남서울대학교',
    grade: 1,
  },
  {
    id: 306,
    name: '백석대학교',
    grade: 1,
  },
  {
    id: 307,
    name: '서남대학교',
    grade: 1,
  },
  {
    id: 308,
    name: '선문대학교',
    grade: 1,
  },
  {
    id: 309,
    name: '세한대학교',
    grade: 2,
  },
  {
    id: 310,
    name: '순천향대학교',
    grade: 4,
  },
  {
    id: 311,
    name: '한국기술교육대학교',
    grade: 3,
  },
  {
    id: 312,
    name: '한국전통문화대학교',
    grade: 1,
  },
  {
    id: 313,
    name: '한서대학교',
    grade: 2,
  },
  {
    id: 314,
    name: '호서대학교',
    grade: 2,
  },
  {
    id: 315,
    name: '군산대학교',
    grade: 2,
  },
  {
    id: 316,
    name: '예수대학교',
    grade: 1,
  },
  {
    id: 317,
    name: '예원예술대학교',
    grade: 3,
  },
  {
    id: 318,
    name: '우석대학교',
    grade: 3,
  },
  {
    id: 319,
    name: '원광대학교',
    grade: 3,
  },
  {
    id: 320,
    name: '전북대학교',
    grade: 4,
  },
  {
    id: 321,
    name: '전주대학교',
    grade: 2,
  },
  {
    id: 322,
    name: '한일장신대학교',
    grade: 1,
  },
  {
    id: 323,
    name: '광주가톨릭대학교',
    grade: 1,
  },
  {
    id: 324,
    name: '동신대학교',
    grade: 1,
  },
  {
    id: 325,
    name: '목포가톨릭대학교',
    grade: 1,
  },
  {
    id: 326,
    name: '목포대학교',
    grade: 2,
  },
  {
    id: 327,
    name: '목포해양대학교',
    grade: 3,
  },
  {
    id: 328,
    name: '순천대학교',
    grade: 1,
  },
  {
    id: 329,
    name: '영산선학대학교',
    grade: 1,
  },
  {
    id: 330,
    name: '초당대학교',
    grade: 1,
  },
  {
    id: 331,
    name: '한려대학교',
    grade: 1,
  },
  {
    id: 332,
    name: '가야대학교',
    grade: 1,
  },
  {
    id: 333,
    name: '경운대학교',
    grade: 2,
  },
  {
    id: 334,
    name: '경일대학교',
    grade: 2,
  },
  {
    id: 335,
    name: '경주대학교',
    grade: 2,
  },
  {
    id: 336,
    name: '금오공과대학교',
    grade: 3,
  },
  {
    id: 337,
    name: '김천대학교',
    grade: 1,
  },
  {
    id: 338,
    name: '대구가톨릭대학교',
    grade: 3,
  },
  {
    id: 339,
    name: '대구대학교',
    grade: 1,
  },
  {
    id: 340,
    name: '대구예술대학교',
    grade: 1,
  },
  {
    id: 341,
    name: '대구외국어대학교',
    grade: 1,
  },
  {
    id: 342,
    name: '대구한의대학교',
    grade: 2,
  },
  {
    id: 343,
    name: '대신대학교',
    grade: 1,
  },
  {
    id: 344,
    name: '동국대학교 WISE캠퍼스',
    grade: 1,
  },
  {
    id: 345,
    name: '동양대학교',
    grade: 1,
  },
  {
    id: 346,
    name: '안동대학교',
    grade: 3,
  },
  {
    id: 347,
    name: '영남대학교',
    grade: 4,
  },
  {
    id: 348,
    name: '영남신학대학교',
    grade: 1,
  },
  {
    id: 349,
    name: '위덕대학교',
    grade: 2,
  },
  {
    id: 350,
    name: '포항공과대학교',
    grade: 7,
  },
  {
    id: 351,
    name: '한동대학교',
    grade: 4,
  },
  {
    id: 352,
    name: '경남과학기술대학교',
    grade: 1,
  },
  {
    id: 353,
    name: '경남대학교',
    grade: 1,
  },
  {
    id: 354,
    name: '경상대학교',
    grade: 3,
  },
  {
    id: 355,
    name: '부산장신대학교',
    grade: 1,
  },
  {
    id: 356,
    name: '인제대학교',
    grade: 1,
  },
  {
    id: 357,
    name: '창신대학교',
    grade: 1,
  },
  {
    id: 358,
    name: '창원대학교',
    grade: 3,
  },
  {
    id: 359,
    name: '한국국제대학교',
    grade: 1,
  },
  {
    id: 360,
    name: '제주국제대학교',
    grade: 1,
  },
  {
    id: 361,
    name: '제주대학교',
    grade: 3,
  },
  {
    id: 362,
    name: '탐라대학교',
    grade: 1,
  },
  {
    id: 363,
    name: '한국방송통신대학교',
    grade: 1,
  },
  {
    id: 364,
    name: '동명정보대학교',
    grade: 1,
  },
  {
    id: 365,
    name: '영산대학교(산업대)',
    grade: 1,
  },
  {
    id: 366,
    name: '광주대학교(산업대)',
    grade: 1,
  },
  {
    id: 367,
    name: '청운대학교',
    grade: 1,
  },
  {
    id: 368,
    name: '호원대학교',
    grade: 3,
  },
  {
    id: 369,
    name: '진주산업대학교',
    grade: 1,
  },
  {
    id: 370,
    name: '정석대학',
    grade: 1,
  },
  {
    id: 371,
    name: '구세군사관학교',
    grade: 1,
  },
  {
    id: 372,
    name: '한국예술종합학교',
    grade: 6,
  },
  {
    id: 373,
    name: '대전신학교',
    grade: 1,
  },
  {
    id: 374,
    name: '순복음총회신학교',
    grade: 1,
  },
  {
    id: 375,
    name: '세계사이버대학',
    grade: 1,
  },
  {
    id: 376,
    name: '영남사이버대학교',
    grade: 1,
  },
  {
    id: 377,
    name: 'SPC식품과학대학',
    grade: 1,
  },
  {
    id: 378,
    name: '현대중공업공과대학',
    grade: 1,
  },
  {
    id: 379,
    name: '포스코기술대학',
    grade: 1,
  },
  {
    id: 380,
    name: '대우조선해양공과대학',
    grade: 1,
  },
  {
    id: 381,
    name: '삼성중공업공과대학',
    grade: 1,
  },
  {
    id: 382,
    name: 'KDB금융대학교',
    grade: 1,
  },
  {
    id: 383,
    name: 'LH토지주택대학교',
    grade: 1,
  },
  {
    id: 384,
    name: '삼성전자공과대학교',
    grade: 1,
  },
  {
    id: 385,
    name: '경희사이버대학교',
    grade: 1,
  },
  {
    id: 386,
    name: '고려사이버대학교',
    grade: 1,
  },
  {
    id: 387,
    name: '디지털서울문화예술대학교',
    grade: 1,
  },
  {
    id: 388,
    name: '사이버한국외국어대학교',
    grade: 1,
  },
  {
    id: 389,
    name: '서울디지털대학교',
    grade: 1,
  },
  {
    id: 390,
    name: '서울사이버대학교',
    grade: 1,
  },
  {
    id: 391,
    name: '세종사이버대학교 ',
    grade: 1,
  },
  {
    id: 392,
    name: '숭실사이버대학교',
    grade: 1,
  },
  {
    id: 393,
    name: '열린사이버대학교',
    grade: 1,
  },
  {
    id: 394,
    name: '한양사이버대학교',
    grade: 1,
  },
  {
    id: 395,
    name: '부산디지털대학교',
    grade: 1,
  },
  {
    id: 396,
    name: '화신사이버대학교',
    grade: 1,
  },
  {
    id: 397,
    name: '건양사이버대학교',
    grade: 1,
  },
  {
    id: 398,
    name: '국제사이버대학교',
    grade: 1,
  },
  {
    id: 399,
    name: '글로벌사이버대학교',
    grade: 1,
  },
  {
    id: 400,
    name: '원광디지털대학교',
    grade: 1,
  },
  {
    id: 401,
    name: '대구사이버대학교',
    grade: 1,
  },
  {
    id: 402,
    name: '영진사이버대학',
    grade: 1,
  },
  {
    id: 403,
    name: '한국복지사이버대학',
    grade: 1,
  },
  {
    id: 404,
    name: '국제예술대학',
    grade: 1,
  },
  {
    id: 405,
    name: '백석예술대학교',
    grade: 4,
  },
  {
    id: 406,
    name: '정화예술대학',
    grade: 3,
  },
  {
    id: 407,
    name: '육군사관학교',
    grade: 6,
  },
  {
    id: 408,
    name: '해군사관학교',
    grade: 6,
  },
  {
    id: 409,
    name: '공군사관학교',
    grade: 6,
  },
  {
    id: 410,
    name: '육군3사관학교',
    grade: 6,
  },
  {
    id: 411,
    name: '국군간호사관학교',
    grade: 6,
  },
  {
    id: 412,
    name: '기타 대학교',
    grade: 1,
  },
  {
    id: 413,
    name: '해외 대학교',
    grade: 5,
  },
  {
    id: 414,
    name: '한양대학교 ERICA',
    grade: 4,
  },
  {
    id: 415,
    name: '경찰대학교',
    grade: 7,
  },
];
export default Universities;
