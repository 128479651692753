const Area = [
  {
    id: 1,
    title: '서울 / 경기',
    content: [
      { id: 1, name: '강남' },
      { id: 2, name: '건대' },
      { id: 3, name: '수원' },
      { id: 4, name: '신촌' },
      { id: 5, name: '인천' },
      { id: 6, name: '홍대' },
    ],
  },
  {
    id: 2,
    title: '대구',
    content: [
      { id: 7, name: '경대 북문' },
      { id: 8, name: '계대 앞' },
      { id: 9, name: '동성로' },
      { id: 10, name: '영대역' },
    ],
  },
  {
    id: 3,
    title: '부산',
    content: [
      { id: 11, name: '경대 앞' },
      { id: 12, name: '부산대 앞' },
      { id: 13, name: '서면' },
      { id: 14, name: '해운대' },
    ],
  },
];

export default Area;
